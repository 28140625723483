import { attachDomaintoPath } from '@imports';

export const generateLink = (
  path: string
): Array<{ rel: string; href: string }> => {
  const pathWithDomain = attachDomaintoPath(path);
  return [
    {
      rel: 'canonical',
      href: pathWithDomain
    }
  ];
};
